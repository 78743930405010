#root .alarm-sidebar-root {
  position: relative;
  min-width: 320px;
  padding: 22px 32px;
  border-left: 2px solid #3e3e3e;
}
#root .alarm-sidebar-root ul {
  height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#root .alarm-sidebar-root ul::-webkit-scrollbar {
  display: none;
}
#root .alarm-sidebar-root ul .alarm-item {
  display: flex;
  align-items: center;
  background-color: #333333;
  border-radius: 4px;
  padding: 12px 0;
}
#root .alarm-sidebar-root ul .alarm-item .title {
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-right: 1px solid #3e3e3e;
  line-height: 18px;
  color: #999999;
  white-space: nowrap;
}
#root .alarm-sidebar-root ul .alarm-item .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;
}
#root .alarm-sidebar-root ul .alarm-item .message .main {
  max-width: 176px;
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
}
#root .alarm-sidebar-root ul .alarm-item .message .sub {
  color: #696969;
  font-size: 12px;
  line-height: 18px;
}
#root .alarm-sidebar-root ul .alarm-item .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
}
#root .alarm-sidebar-root ul .alarm-item .button-container .btn {
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}
#root .alarm-sidebar-root ul .alarm-item .button-container .btn.reject {
  color: #5b89d9;
}
#root .alarm-sidebar-root ul .alarm-item .button-container .btn.allow {
  color: #e5a120;
}/*# sourceMappingURL=alarm.sidebar.styles.css.map */