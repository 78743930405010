.name-popup-root {
  width: 360px;
  height: 305px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.name-popup-root .title-area {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #333333;
}
.name-popup-root .title-area .title-text {
  font-weight: medium;
  color: #ffffff;
  font-size: 18px;
}
.name-popup-root .content-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name-popup-root .content-area .text {
  text-align: center;
  font-size: 16px;
  font-weight: medium;
  color: #767676;
}
.name-popup-root .content-area .name-input {
  margin-top: 12px;
  width: 300px;
  height: 56px;
  border: 1px solid #707070;
  border-radius: 28px;
  background-color: #121212;
  padding: 0px 20px;
  color: #ffffff;
  font-size: 16px;
}
.name-popup-root .button-area {
  height: 62px;
  display: flex;
}
.name-popup-root .button-area button {
  flex: 1;
  font-size: 18px;
  font-weight: medium;
}
.name-popup-root .button-area .cancel-button {
  color: #767676;
}
.name-popup-root .button-area .confirm-button {
  color: #5b89d9;
}/*# sourceMappingURL=name.popup.style.css.map */