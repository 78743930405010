#root .login-root {
  background-color: #121212;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .login-root .login-container {
  background-color: #222222;
  padding: 90px 125px 142px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .login-root .login-container h1 {
  color: #ffffff;
  size: 26px;
  line-height: 23px;
  margin-bottom: 64px;
}
#root .login-root .login-container .input-container {
  border-radius: 28px;
}
#root .login-root .login-container .input-container input {
  line-height: 23px;
}
#root .login-root .login-container .input-container.id {
  margin-bottom: 16px;
}
#root .login-root .login-container .input-container.pw {
  margin-bottom: 40px;
}
#root .login-root .login-container .input-container .btn {
  cursor: pointer;
}
#root .login-root .login-container .login-btn {
  background-color: #004680;
  width: 100%;
  border-radius: 28px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 23px;
}/*# sourceMappingURL=login.styles.css.map */