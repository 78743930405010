* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard", sans-serif;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: 16px;
}

input {
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

#root .input-container {
  width: 380px;
  border: 1px solid #999999;
  padding: 18px 20px;
  color: #999999;
  display: flex;
  background-color: #121212;
}
#root .input-container input {
  flex: 1;
  color: #ffffff;
}
#root .input-container input::-moz-placeholder {
  color: #999999;
}
#root .input-container input:-ms-input-placeholder {
  color: #999999;
}
#root .input-container input::placeholder {
  color: #999999;
}
#root .input-container:focus-within {
  border: 2px solid #004680;
  color: #ffffff;
}
#root .table {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#root .table .table-header {
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 32px;
}
#root .table .table-header .col {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #999999;
}
#root .table .table-body {
  flex: 1;
}
#root .table .table-body ul {
  display: flex;
  flex-direction: column;
}
#root .table .table-body ul .list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 16px 0;
  border-bottom: 1px solid #3e3e3e;
}
#root .table .table-body ul .list-item .col {
  font-size: 16px;
  color: #ffffff;
}
#root .table .table-body ul .list-item:hover {
  background-color: #1c2328;
}
#root .table .table-body ul .list-item:last-child {
  border-bottom: none;
}/*# sourceMappingURL=core.css.map */