.board-root {
  display: flex;
  height: 100%;
  background-color: #121212;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}
.board-root::-webkit-scrollbar {
  display: none;
}
.board-root .button-wrapper {
  width: 1170px;
  margin-top: 19px;
}
.board-root .button-wrapper button {
  width: 73px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid;
}
.board-root .button-wrapper .start-button {
  color: #1a8d8d;
  border-color: #1a8d8d;
  background-color: #111d1d;
}
.board-root .button-wrapper .end-button {
  margin-right: 10px;
  color: #1a8d8d;
  border-color: #1a8d8d;
  background-color: #111d1d;
}
.board-root .button-wrapper .reset-button {
  color: #d16561;
  border-color: #d16561;
  background-color: #251919;
}
.board-root .time-sender {
  margin-top: 16px;
  display: flex;
  gap: 10px;
}
.board-root .time-sender .select-section {
  width: 820px;
}
.board-root .time-sender .select-section .time-select {
  display: flex;
  gap: 10px;
}
.board-root .time-sender .select-section .time-select:first-child {
  margin-bottom: 10px;
}
.board-root .time-sender .select-section .time-select .time-button {
  min-width: 120px;
  width: 100%;
  max-width: 156px;
  height: 48px;
  background-color: #333333;
  color: #ffffff;
  font-size: 15px;
}
.board-root .time-sender .input-section .row {
  display: flex;
  height: 48px;
  align-items: center;
}
.board-root .time-sender .input-section .row:first-child {
  margin-bottom: 10px;
}
.board-root .time-sender .input-section .row .sign-toggle {
  position: relative;
  align-items: center;
  display: flex;
  width: 72px;
  background-color: #000000;
  height: 100%;
}
.board-root .time-sender .input-section .row .sign-toggle .sign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 100%;
  color: #ffffff;
  font-size: 24px;
}
.board-root .time-sender .input-section .row .sign-toggle .sign span {
  z-index: 2;
}
.board-root .time-sender .input-section .row .sign-toggle .switch {
  width: 36px;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #004680;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}
.board-root .time-sender .input-section .row .sign-toggle .minus {
  transform: translateX(36px);
}
.board-root .time-sender .input-section .row .input {
  max-width: 60px;
  height: 100%;
  margin: 0px 6px;
  color: #ffffff;
  font-size: 24;
  font-weight: 600;
  text-align: center;
  border: 1px solid #707070;
}
.board-root .time-sender .input-section .row .text {
  font-size: 15px;
  color: #d8d8d8;
}
.board-root .time-sender .input-section .row .send-button {
  width: 100%;
  height: 100%;
  max-width: 340px;
  background-color: #333333;
  color: #ffffff;
}
.board-root .user-board-container {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(240px, 285px));
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
  height: calc(100vh - 310px);
  max-height: 770px;
}
.board-root .user-board-container .user-item-root {
  width: 100%;
  background-color: #333333;
  padding: 16px 16px 36px 16px;
}
.board-root .user-board-container .user-item-root * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
}
.board-root .user-board-container .user-item-root .header {
  height: 24px;
  display: flex;
  justify-content: space-between;
}
.board-root .user-board-container .user-item-root .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.board-root .user-board-container .user-item-root .content .username {
  margin-top: 16px;
  font-size: 24px;
  cursor: pointer;
}
.board-root .user-board-container .user-item-root .content .red-time {
  margin-top: 23px;
  font-size: 46px;
  font-weight: 600;
}
.board-root .user-board-container .user-item-root .content .blue-time {
  margin-top: 12px;
  font-size: 32px;
  font-weight: 600;
}
.board-root .user-board-container .checked .username,
.board-root .user-board-container .unchecked .username {
  color: #999999;
}
.board-root .user-board-container .checked .red-time,
.board-root .user-board-container .unchecked .red-time {
  color: #f0566a;
}
.board-root .user-board-container .checked .blue-time,
.board-root .user-board-container .unchecked .blue-time {
  color: #0082ff;
}
.board-root .user-board-container .disabled .header .check-box {
  cursor: default;
}
.board-root .user-board-container .disabled .username {
  color: #4b4b4b;
}
.board-root .user-board-container .disabled .red-time {
  color: #6a3d42;
}
.board-root .user-board-container .disabled .blue-time {
  color: #234a6f;
}/*# sourceMappingURL=board.style.css.map */