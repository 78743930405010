#root .main-navigation-root {
  background-color: #222222;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#root .main-navigation-root .main {
  display: flex;
  flex: 1;
}
#root .main-navigation-root .main .main-content {
  flex: 1;
}
#root .popup-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: scroll;
}
#root .popup-container::-webkit-scrollbar {
  display: none;
}
#root .popup-container .backdrop {
  position: fixed;
  background-color: #000;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
  z-index: 5;
}
#root .popup-container .popup {
  z-index: 6;
  transition: transform 0.3s ease-in-out;
}/*# sourceMappingURL=main.navigation.styles.css.map */