#root .cpaging-root {
  display: flex;
  align-items: center;
}
#root .cpaging-root button {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .cpaging-root .move-btn {
  margin: 8px;
  background-color: #3e3e3e;
}
#root .cpaging-root .move-btn img {
  width: 20px;
  height: 20px;
}
#root .cpaging-root .number-btn {
  background-color: transparent;
  color: #ffffff;
  font-size: 15px;
  line-height: 32px;
}
#root .cpaging-root .number-btn.current {
  background-color: #1c2328;
  color: #5b89d9;
}/*# sourceMappingURL=cpaging.styles.css.map */