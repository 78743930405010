$black: #121212;
$gray-100: #222222;
$gray-200: #333333;
$gray-300: #999999;
$gray-400: #4b4b4b;
$white: #ffffff;
$primary: #5b89d9;
$primary-highlight: #004680;
$primary-darken: #004680;
$main-border: #3e3e3e;
