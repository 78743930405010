@import '../../../styles/color';

#root {
  .send-root {
    height: 100%;
    background-color: $black;
    padding: 70px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    .button-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .btn {
        background-color: transparent;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;

        &.reject {
          color: $primary;
        }

        &.allow {
          color: #e5a120;
        }
      }
    }
  }
}
