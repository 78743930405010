@import '../../../styles/color';

#root {
  .buy-root {
    height: 100%;
    background-color: $black;
    padding: 70px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }
}
