#root .send-root {
  height: 100%;
  background-color: #121212;
  padding: 70px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}
#root .send-root .button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
#root .send-root .button-container .btn {
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}
#root .send-root .button-container .btn.reject {
  color: #5b89d9;
}
#root .send-root .button-container .btn.allow {
  color: #e5a120;
}/*# sourceMappingURL=send.styles.css.map */