@import '../../../styles/color';

#root {
  .total-root {
    height: 100%;
    background-color: $black;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    .date-picker-container {
      align-self: flex-end;

      border: 1px solid #707070;
      width: 230px;
      padding: 10px;

      .datepicker-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
      }

      input[type='date'] {
        color: $white;
        width: 100%;
        display: flex;
        font-size: 15px;

        &::-webkit-calendar-picker-indicator {
          background: url(/public/assets/images/ic-date.png) no-repeat;
          width: 20px;
          height: 18px;
        }
      }
    }

    & > .table > .table-header {
      justify-content: flex-start;
      padding: 16px 160px;
    }

    & > .table > .table-body > ul {
      .list-item {
        justify-content: flex-start;
        padding: 16px 160px;
      }
    }
  }
}
