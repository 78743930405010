.livetime-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  background-color: #121212;
}
.livetime-root input[type=number]::-webkit-outer-spin-button,
.livetime-root input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.livetime-root input[type=date]::-webkit-calendar-picker-indicator,
.livetime-root input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  cursor: pointer;
}
.livetime-root .time-picker {
  display: flex;
  gap: 16px;
}
.livetime-root .time-picker input {
  width: 240px;
  height: 56px;
  padding: 8px 12px;
  background-color: #121212;
  border: 1px solid #707070;
  color: #ffffff;
  font-size: 18px;
}
.livetime-root .time-picker input::-moz-placeholder {
  color: #ffffff;
}
.livetime-root .time-picker input:-ms-input-placeholder {
  color: #ffffff;
}
.livetime-root .time-picker input::placeholder {
  color: #ffffff;
}
.livetime-root .button-wrapper {
  margin-top: 24px;
  display: flex;
  gap: 24px;
}
.livetime-root .button-wrapper button {
  width: 96px;
  height: 48px;
  font-size: 18px;
  border-radius: 16px;
  border: 1px solid;
}
.livetime-root .button-wrapper .reset-button {
  color: #d16561;
  border-color: #d16561;
  background-color: #251919;
}
.livetime-root .button-wrapper .setting-button {
  color: #1a8d8d;
  border-color: #1a8d8d;
  background-color: #111d1d;
}/*# sourceMappingURL=livetime.style.css.map */