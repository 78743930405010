@import '../../../../styles/color';

#root {
  .cpaging-root {
    display: flex;
    align-items: center;

    button {
      width: 32px;
      height: 32px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .move-btn {
      margin: 8px;
      background-color: $main-border;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .number-btn {
      background-color: transparent;
      color: $white;
      font-size: 15px;
      line-height: 32px;

      &.current {
        background-color: #1c2328;
        color: $primary;
      }
    }
  }
}
