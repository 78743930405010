#root .gnb-root {
  width: 240px;
  border-right: 2px solid #3e3e3e;
  padding-top: 70px;
}
#root .gnb-root ul {
  display: flex;
  flex-direction: column;
}
#root .gnb-root ul .tab {
  display: flex;
  gap: 10px;
  color: #999999;
  padding: 18px 32px;
  border-left: 4px solid transparent;
  cursor: pointer;
}
#root .gnb-root ul .tab.active {
  background-color: #1f262c;
  color: #5b89d9;
  border-left: 4px solid #004680;
}
#root .gnb-root ul .tab .icon {
  width: 20px;
  height: 20px;
}/*# sourceMappingURL=gnb.styles.css.map */