.end-popup-root {
  width: 360px;
  height: 305px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.end-popup-root .title-area {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #333333;
}
.end-popup-root .title-area .title-text {
  font-weight: medium;
  color: #ffffff;
  font-size: 18px;
}
.end-popup-root .content-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 45px;
}
.end-popup-root .content-area .text {
  text-align: center;
  font-size: 16px;
  font-weight: medium;
  color: #767676;
}
.end-popup-root .content-area .red {
  color: #d16561;
  margin-bottom: 12px;
}
.end-popup-root .button-area {
  height: 62px;
  display: flex;
}
.end-popup-root .button-area button {
  flex: 1;
  font-size: 18px;
  font-weight: medium;
}
.end-popup-root .button-area .cancel-button {
  color: #767676;
}
.end-popup-root .button-area .confirm-button {
  color: #5b89d9;
}/*# sourceMappingURL=end.popup.style.css.map */