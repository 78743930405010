@import '../../../styles/color';

#root {
  .gnb-root {
    width: 240px;
    border-right: 2px solid $main-border;
    padding-top: 70px;

    ul {
      display: flex;
      flex-direction: column;

      .tab {
        display: flex;
        gap: 10px;
        color: $gray-300;
        padding: 18px 32px;
        border-left: 4px solid transparent;
        cursor: pointer;

        &.active {
          background-color: #1f262c;
          color: $primary;
          border-left: 4px solid $primary-highlight;
        }

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
