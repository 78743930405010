@import '../../../styles/color';

#root {
  .alarm-sidebar-root {
    position: relative;
    min-width: 320px;
    padding: 22px 32px;
    border-left: 2px solid $main-border;

    ul {
      // position: sticky;
      height: calc(100vh - 116px);
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .alarm-item {
        display: flex;
        align-items: center;
        background-color: $gray-200;
        border-radius: 4px;
        padding: 12px 0;

        .title {
          height: 78px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 25px;
          border-right: 1px solid $main-border;
          line-height: 18px;
          color: $gray-300;
          white-space: nowrap;
        }

        .message {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 16px;
          gap: 8px;

          .main {
            max-width: 176px;
            color: $white;
            font-size: 15px;
            line-height: 20px;
          }

          .sub {
            color: #696969;
            font-size: 12px;
            line-height: 18px;
          }
        }

        .button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          padding: 16px;

          .btn {
            background-color: transparent;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            white-space: nowrap;

            &.reject {
              color: $primary;
            }

            &.allow {
              color: #e5a120;
            }
          }
        }
      }
    }
  }
}
