@import '../../../styles/color';

.bank-root {
  display: flex;
  height: 100%;
  background-color: $black;
  flex-direction: column;
  align-items: center;
  padding: 0px 32px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;

  .interest-input-section {
    width: 100%;
    max-width: 1170px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
    height: 48px;

    .text {
      font-size: 15px;
      color: $white;
      margin-right: 8px;
    }

    .input {
      width: 232px;
      height: 48px;
      padding: 16px;
      border: 1px solid #707070;
      color: $white;
      margin-right: 8px;
    }

    .adjust-button {
      width: 60px;
      height: 48px;
      background-color: #333333;
      color: $white;
    }
  }

  .time-sender {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    .select-section {
      width: 820px;
      .time-select {
        display: flex;
        gap: 10px;
        &:first-child {
          margin-bottom: 10px;
        }

        .time-button {
          min-width: 120px;
          width: 100%;
          max-width: 156px;
          height: 48px;
          background-color: $gray-200;
          color: $white;
          font-size: 15px;
        }
      }
    }

    .input-section {
      .row {
        display: flex;
        height: 48px;
        align-items: center;

        &:first-child {
          margin-bottom: 10px;
        }

        .sign-toggle {
          position: relative;
          align-items: center;
          display: flex;
          width: 72px;
          background-color: #000000;
          height: 100%;

          .sign {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 100%;
            color: $white;
            font-size: 24px;

            span {
              z-index: 2;
            }
          }

          .switch {
            width: 36px;
            height: 100%;
            position: absolute;
            z-index: 1;
            background-color: $primary-darken;
            transition: transform 0.5s ease-in-out;
            transform: translateX(0);
          }

          .minus {
            transform: translateX(36px);
          }
        }

        .input {
          max-width: 60px;
          height: 100%;
          margin: 0px 6px;
          color: $white;
          font-size: 24;
          font-weight: 600;
          text-align: center;
          border: 1px solid #707070;
        }

        .text {
          font-size: 15px;
          color: #d8d8d8;
        }

        .send-button {
          width: 100%;
          height: 100%;
          max-width: 340px;
          background-color: $gray-200;
          color: $white;
        }
      }
    }
  }

  .user-board-container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(4, minmax(240px, 285px));
    column-gap: 10px;
    row-gap: 10px;
    height: calc(100vh - 310px);
    max-height: 770px;

    .user-item-root {
      width: 100%;
      height: 250px;
      background-color: $gray-200;
      padding: 16px 16px 36px 16px;

      .header {
        height: 24px;
        display: flex;
        justify-content: space-between;
      }

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .username {
          margin-top: 16px;
          font-size: 24px;
        }
        .red-time {
          margin-top: 23px;
          font-size: 46px;
          font-weight: 600;
        }
        .blue-time {
          margin-top: 12px;
          font-size: 32px;
          font-weight: 600;
        }
      }
    }

    .checked,
    .unchecked {
      .username {
        color: $white;
      }
      .red-time {
        color: $white;
      }
    }
  }
}
