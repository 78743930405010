header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  border-bottom: 2px solid #3e3e3e;
}
header h1 {
  font-size: 26px;
  color: #ffffff;
  font-weight: 600;
}
header button {
  position: absolute;
  right: 32px;
  background-color: #222222;
  color: #ffffff;
  text-decoration: underline;
}/*# sourceMappingURL=header.styles.css.map */