.end-popup-root {
  width: 360px;
  height: 305px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .title-area {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #333333;

    .title-text {
      font-weight: medium;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 45px;

    .text {
      text-align: center;
      font-size: 16px;
      font-weight: medium;
      color: #767676;
    }

    .red {
      color: #d16561;
      margin-bottom: 12px;
    }
  }

  .button-area {
    height: 62px;
    display: flex;

    button {
      flex: 1;
      font-size: 18px;
      font-weight: medium;
    }

    .cancel-button {
      color: #767676;
    }

    .confirm-button {
      color: #5b89d9;
    }
  }
}
