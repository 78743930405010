@import '../../../styles/color';

header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  border-bottom: 2px solid $main-border;

  h1 {
    font-size: 26px;
    color: $white;
    font-weight: 600;
  }

  button {
    position: absolute;
    right: 32px;
    background-color: $gray-100;
    color: $white;
    text-decoration: underline;
  }
}
