.name-popup-root {
  width: 360px;
  height: 305px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .title-area {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #333333;

    .title-text {
      font-weight: medium;
      color: #ffffff;
      font-size: 18px;
    }
  }

  .content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      text-align: center;
      font-size: 16px;
      font-weight: medium;
      color: #767676;
    }

    .name-input {
      margin-top: 12px;
      width: 300px;
      height: 56px;
      border: 1px solid #707070;
      border-radius: 28px;
      background-color: #121212;
      padding: 0px 20px;
      color: #ffffff;
      font-size: 16px;
    }
  }

  .button-area {
    height: 62px;
    display: flex;

    button {
      flex: 1;
      font-size: 18px;
      font-weight: medium;
    }

    .cancel-button {
      color: #767676;
    }

    .confirm-button {
      color: #5b89d9;
    }
  }
}
