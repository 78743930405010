@import '../../styles/color';

#root {
  .main-navigation-root {
    background-color: $gray-100;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main {
      display: flex;
      flex: 1;

      .main-content {
        flex: 1;
      }
    }
  }

  .popup-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    overflow-y: scroll;
    overflow-x: scroll;

    .backdrop {
      position: fixed;
      background-color: #000;
      width: 100%;
      height: 100vh;
      opacity: 0.6;
      z-index: 5;
    }

    .popup {
      z-index: 6;
      transition: transform 0.3s ease-in-out;
    }
  }
}
