#root .total-root {
  height: 100%;
  background-color: #121212;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}
#root .total-root .date-picker-container {
  align-self: flex-end;
  border: 1px solid #707070;
  width: 230px;
  padding: 10px;
}
#root .total-root .date-picker-container .datepicker-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}
#root .total-root .date-picker-container input[type=date] {
  color: #ffffff;
  width: 100%;
  display: flex;
  font-size: 15px;
}
#root .total-root .date-picker-container input[type=date]::-webkit-calendar-picker-indicator {
  background: url(/public/assets/images/ic-date.png) no-repeat;
  width: 20px;
  height: 18px;
}
#root .total-root > .table > .table-header {
  justify-content: flex-start;
  padding: 16px 160px;
}
#root .total-root > .table > .table-body > ul .list-item {
  justify-content: flex-start;
  padding: 16px 160px;
}/*# sourceMappingURL=total.styles.css.map */