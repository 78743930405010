@import './color';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Pretendard', sans-serif;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: 16px;
}

input {
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

#root {
  .input-container {
    width: 380px;
    border: 1px solid $gray-300;
    padding: 18px 20px;
    color: $gray-300;
    display: flex;
    background-color: $black;

    input {
      flex: 1;
      color: $white;
    }

    input::placeholder {
      color: $gray-300;
    }

    &:focus-within {
      border: 2px solid $primary-highlight;
      color: $white;
    }
  }

  .table {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .table-header {
      background-color: $gray-100;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 32px;

      .col {
        display: block;
        font-size: 16px;
        font-weight: 600;
        color: $gray-300;
      }
    }

    .table-body {
      flex: 1;

      ul {
        display: flex;
        flex-direction: column;

        .list-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 32px;
          padding: 16px 0;
          border-bottom: 1px solid $main-border;

          .col {
            font-size: 16px;
            color: $white;
          }

          &:hover {
            background-color: #1c2328;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
