@import '../../../styles/color';

#root {
  .login-root {
    background-color: $black;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-container {
      background-color: $gray-100;
      padding: 90px 125px 142px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: $white;
        size: 26px;
        line-height: 23px;
        margin-bottom: 64px;
      }

      .input-container {
        border-radius: 28px;

        input {
          line-height: 23px;
        }

        &.id {
          margin-bottom: 16px;
        }

        &.pw {
          margin-bottom: 40px;
        }

        .btn {
          cursor: pointer;
        }
      }

      .login-btn {
        background-color: $primary-highlight;
        width: 100%;
        border-radius: 28px;
        padding: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}
