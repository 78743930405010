@import '../../../styles/color';

.livetime-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  background-color: $black;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(100%);
    cursor: pointer;
  }

  .time-picker {
    display: flex;
    gap: 16px;
    input {
      width: 240px;
      height: 56px;
      padding: 8px 12px;
      background-color: #121212;
      border: 1px solid #707070;
      color: #ffffff;
      font-size: 18px;

      &::placeholder {
        color: #ffffff;
      }
    }
  }

  .button-wrapper {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    button {
      width: 96px;
      height: 48px;
      font-size: 18px;
      border-radius: 16px;
      border: 1px solid;
    }

    .reset-button {
      color: #d16561;
      border-color: #d16561;
      background-color: #251919;
    }

    .setting-button {
      color: #1a8d8d;
      border-color: #1a8d8d;
      background-color: #111d1d;
    }
  }
}
